<template>
  <el-container>
    <Header pageName="应用订单"></Header>
    <el-main>
      <el-form class="el-form-search" label-width="100px">
        <el-form-item label="订单编号：">
          <el-input v-model="searchForm.keywords" size="small" placeholder="请输入订单编号"></el-input>
        </el-form-item>
        <el-form-item label="店铺名称：">
          <el-input v-model="searchForm.storename" size="small" placeholder="请输入店铺名称"></el-input>
        </el-form-item>
        <el-form-item label="支付时间：">
          <el-date-picker
            v-model="searchForm.paytime"
            size="small"
            type="daterange"
            value-format="timestamp"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="30px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="ordersn" label="订单编号" align="center"></el-table-column>
        <el-table-column prop="meal_name" label="订单套餐" align="center"></el-table-column>
        <el-table-column prop="u_account" label="用户名" align="center"></el-table-column>
        <el-table-column prop="money" label="支付金额" align="center">
          <template v-slot="{ row }">￥{{ row.money }}</template>
        </el-table-column>
        <el-table-column prop="shop_name" label="店铺名称" align="center"></el-table-column>
        <el-table-column prop="create_time" label="支付时间" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </el-container>
</template>

<script>
import Header from './components/header';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
  components: {
    Header,
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        keywords: '',
        storename: '',
        paytime: '',
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        keywords: '',
        storename: '',
        paytime: '',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.paytime) {
        for (let i in searchForm.paytime) {
          searchForm.paytime[i] = searchForm.paytime[i] / 1000;
        }
      }
      this.$axios.post(this.$api.admin.orderList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
  }
}
</style>